import axios from '../config/axios';

export const url = '/api/logpayment';

export const createPaymentByAdminConsultant = ({
  token, currency, email, description, total
}) => new Promise((resolve, reject) => {
  if (currency && email && description && total && token) {
    axios.post(`${url}/create/consulting`, {
      currency, email, description, total
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else if (!currency) {
    reject({
      status: 'error',
      info: 'Moneda no definido'
    });
  } else if (!email) {
    reject({
      status: 'error',
      info: 'Correo no definido'
    });
  } else if (!description) {
    reject({
      status: 'error',
      info: 'Descripción no definida'
    });
  } else if (!total) {
    reject({
      status: 'error',
      info: 'Monto no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const listLogPayment = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
})

