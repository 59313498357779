import axios from '../config/axios';

export const url = '/api/reset';

export const createResetToken = ({email,}) => new Promise((resolve, reject) => {
  if (email) {
    axios.post(`${url}/create`, {email})
      .then((res) => {
        const {data} = res;
        if (data.status === 'success') {
          resolve(data);
        } else {
          reject(data);
        }
      }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Correo no definido',
    });
  }
});

export const resetPassword = ({password, confirmPassword, token,}) => new Promise((resolve, reject) => {
  if (password && confirmPassword && password === confirmPassword && token) {
    axios.post(`${url}/reset-password`, {
      password, confirmPassword, token,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!password) {
    reject({
      status: 'error',
      info: 'Contraseña no definida',
    });
  } else if (!confirmPassword) {
    reject({
      status: 'error',
      info: 'Confirmar contraseña no definida',
    });
  } else if (password !== confirmPassword) {
    reject({
      status: 'error',
      info: 'Las contraseñas no coinciden',
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
