import { useState, useEffect, useCallback } from 'react';
import DynamicTable from '../components/dynamicTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SoftInput from "components/SoftInput";
import AddPromotionalCode from 'components/AddPromotionalCode';

// AXIOS API
import { listCodePromotionForPartner } from '../api/promotionalCode.api';

function PromotionalCodeData({ history, token }) {

    const [datos, setDatos] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState("");

    const getData = useCallback(async (page, filter) => {
        try {
            const res = await listCodePromotionForPartner({ token });
            setDatos(res.data.map((item) => ({
                id: item._id,
                code: item.code,
                amount: item.amount,
                initialDate: item.initialDate,
                endDate: item.endDate,
                isActive: item.active ? 'Active' : 'Inactive',
            })));
            setTotalPages(res.totalPages || 1);
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching promotional codes:", error);
            setIsLoading(false);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            getData(page, filter);
        }
    }, [token, page, filter, getData]);

    function setNextPage() {
        setIsLoading(true);
        getData(page + 1, filter);
        setPage(page + 1);
    }

    function setPreviousPage() {
        setIsLoading(true);
        getData(page - 1, filter);
        setPage(page - 1);
    }

    function requestSearch() {
        setIsLoading(true);
        getData(1, filter);
        setPage(1);
    }

    // Elimina la función cancelSearch ya que no se está usando

    if (!token) {
        return <Redirect to="/" />;
    }

    return (datos && !isLoading ? (
        <Layout>
            <div>
                <header>
                    <div className='user-table-bar'>
                        <div className='user-table-header'>
                            <h1>Promotional Codes</h1>
                        </div>
                    </div>
                </header>
            </div>
            <div className="grid-container">
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <SoftInput
                            placeholder="Search by code"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant="contained" onClick={requestSearch}>Search</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <AddPromotionalCode handleAddCode={getData} token={token} />
                    </Grid>
                </Grid>
            </div>

            <div>
                {datos.length !== 0 &&
                    <DynamicTable title="Promotional Codes" data={datos} token={token} />
                }
            </div>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <p>Page: {page}</p>
                </Grid>
            </Grid>
            <Grid container spacing={2}>

                <Grid item xs={2}>
                    {page > 1 && page < totalPages &&
                        <Button variant="contained" onClick={setPreviousPage}>
                            Previous
                        </Button>
                    }
                </Grid>
                <Grid item xs={2}>
                    {page < totalPages &&
                        <Button variant="contained" onClick={setNextPage}>
                            Next
                        </Button>
                    }
                </Grid>

            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    {page >= totalPages && totalPages > 1 &&
                        <Button variant="contained" onClick={setPreviousPage}>
                            Previous
                        </Button>
                    }
                </Grid>
            </Grid>
        </Layout>

    ) : (
        <Layout>
            <h1>Loading...</h1>
        </Layout>
    )
    );
}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(PromotionalCodeData);