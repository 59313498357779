/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import Table from "examples/Tables/Table";
/*
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
*/
import { useState } from 'react';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { ThemeProvider } from '@emotion/react';
import theme from "assets/theme";
import ModalProjectTemplateConfig from "./ModalProjectTemplateConfig";
import { updateProjectDeployedCode } from '../api/project.api';
import ModalInfoPartner from "./ModalInfoPartner";

function ProjectTable({ data, token, haveComponent, component }) {
	const headers = Object.keys(data[0]);
	let history = useHistory()
	const [dataTable, setDataTable] = useState(data);
	const [selectedValue, setSelectedValue] = useState();
	const [openDialogTemplate, setOpenDialogTemplate] = useState(false); 
	const [columnData, setColumnData] = useState([]);
	const [rowData, setRowData] = useState([]);
  const [loadedData, setLoadedData] = useState(false);
	const openTemplate = (data) => {
		setSelectedValue(data);
		setOpenDialogTemplate(true);
	}
	async function updateProject(data) {
		try {
			await updateProjectDeployedCode({
				token, idProject: data?._id
			});
			alert('Se actualizo de manera exitosa');
		} catch (error) {
			alert('Error al procesar la petición');
		}
	}

	const checkClick = (value)=>{
		// console.log(value)
		const stateValue = [];
		for (var key in value) {
			if(key !== 'view'){
				stateValue.push(value[key]);
			}
		}
		
		if(value.slug){
			history.push({ pathname: `/project/${value?._id}`, state: stateValue})
		}
		if(value.lastName){
			history.push({ pathname: `/profile/${value?._id}`, state:  stateValue })
		}
		
	}

  useEffect(() => {
    const tempColumns = [];
    const tempRows = [];
    for (let index = 0; index < headers.length; index++) {
      tempColumns.push({name: headers[index], align: 'left'})
    }
    tempColumns.push({name: 'view', align: 'left'});
    tempColumns.push({name: 'template', align: 'left'});
    tempColumns.push({name: 'actions', align: 'left'});
    for(let index = 0; index < data.length; index++){
      var temp = {};
      temp = dataTable[index];
      if (temp) {
        if(temp['_id'] && (temp['slug'] || temp['lastName'])){
          temp['view'] = (<Button onClick={()=>checkClick(data[index])}>View</Button>);
        }
        temp['template'] = (<Button onClick={()=>openTemplate(data[index])}>Template</Button>);
        if (temp['deployed']) {
          temp['actions'] = (
            <>
              {data[index]?.deployed === 'Si' && (
                <Button onClick={()=>updateProject(data[index])}>Update</Button>
              )}
            </>
          );
        }
        tempRows.push(temp);
      }
    }
    setColumnData(tempColumns);
    setRowData(tempRows);
    setLoadedData(true);
  }, [])

  if (!loadedData) {
    return (
      <h2 className="text-center mt-3 mb-3">
				Loading...
			</h2>
    )
  }

	return (
		
		<div>
			<ModalProjectTemplateConfig
				open={openDialogTemplate}
				onClose={() => setOpenDialogTemplate(false)}
				projectInfo={selectedValue}
				token={token}
			/>
			<ModalInfoPartner
			
			/>
			<ThemeProvider theme={theme}>
			{
				data.length > 0 ? (
					<Table
						columns={columnData}
						rows={rowData}
					/>
				) : (
					<h2 className="text-center mt-3 mb-3">
						No hay datos
					</h2>
				)
			}
			</ThemeProvider>
		</div>
	);
}


export default ProjectTable;