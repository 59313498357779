/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Layout from '../layouts/DashboardLayout';
import { Redirect } from 'react-router-dom';
import Box from '@mui/material/Box';
import { updateInfo } from '../api/useradmin.api';
import { changePassword } from '../api/useradmin.api';
const Profile = ({ user, token }) => {

  const [isDisabled, setIsDisabled] = useState(true);
  const [userDefaultName, setUserDefaultName] = useState("")
  const [userName, setUserName] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const [changePassState, setChangePassState] = useState(false);

  const updateInfoUser = async () => {
    const fullName = userName;
    const res = await updateInfo({ fullName, token })
    setUserDefaultName(fullName)
    console.log(res)
    setIsDisabled(!isDisabled)
  }

  const updatePasswordInfo = async () => {
    const res = await changePassword({ newPassword, confirmPassword, token });
    console.log(res)
    setChangePassState(!changePassState)
  }

  const cancelUserUpdate = () => {
    setIsDisabled(!isDisabled)
    setUserName(userDefaultName)
  }

  const cancelChangePass = () => {
    setChangePassState(!changePassState)
    setNewPassword("")
    setConfirmPassword("")
  }

  useEffect(() => {
    if (token) {
      setUserName(user.fullName)
      setUserDefaultName(user.fullName)
      setUserEmail(user.email)
    }
  }, [])

  if (!token) {
    return (
      <Redirect to="/" />
    )
  }
  return (
    <Layout>
      <div>
        <header>
          <div className='user-table-bar'>
            <div className='user-table-header'>
              <h1>My Profile</h1>
            </div>
          </div>
        </header>
      </div>

      <div className='grid-container'>
        <div style={{ alignItems: 'center', alignContent: 'center' }}>
          <Box
            sx={{
              width: 1250,
              height: 'auto',
              marginLeft: 5,
              marginTop: 5
            }}
          >
            <Grid container spacing={1} style={{ padding: 40 }}>
              <Grid item xs={5}>
                <TextField
                  id="user-id-name"
                  label={"Name"}
                  variant="outlined"
                  value={userName}
                  disabled={isDisabled}
                  fullWidth
                  margin="dense"
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Grid>

            </Grid>
            <Grid container spacing={1} style={{ padding: 40 }}>
              <Grid item xs={5}>
                <TextField
                  id="user-email"
                  label={"email"}
                  variant="outlined"
                  value={userEmail}
                  disabled
                  fullWidth
                  margin="dense"

                />
              </Grid>
            </Grid>
            <Grid container spacing={1} style={{ padding: 40 }}>
              {isDisabled &&
                <Grid item xs={2}>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setIsDisabled(!isDisabled)}>
                    Editar
                  </Button>
                </Grid>
              }
              {!isDisabled &&
                
                  <Grid container spacing={1} style={{ padding: 40 }}>
                    <Grid item xs={3}>
                      <Button
                        color="secondary"
                        variant='outlined'
                        onClick={cancelUserUpdate}
                      >
                        Cancelar
                      </Button>
                    </Grid>
                    <Grid item xs={3}>
                      <Button
                        color="primary"
                        variant='outlined'
                        onClick={updateInfoUser}
                      >
                        Guardar
                      </Button>
                    </Grid>
                  </Grid>
                
              }
            </Grid>
            {!changePassState &&
              <Grid container spacing={1} style={{ padding: 40 }}>
                <Grid item xs={3}>

                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => setChangePassState(!changePassState)}>
                    Cambiar Contraseña
                  </Button>

                </Grid>
              </Grid>
            }
            {changePassState &&
              <div>
                <Grid container spacing={2} style={{ padding: 40 }}>
                  <Grid item xs={4}>
                    <TextField
                      id="newPassword"
                      label={"New Password"}
                      variant="outlined"
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="confirmPassword"
                      label={"Confirm Password"}
                      variant="outlined"
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      fullWidth
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                <Grid container style={{ padding: 40 }}>
                  <Grid item xs={2}>
                    <Button
                      color="secondary"
                      variant='outlined'
                      onClick={cancelChangePass}>
                      Cancelar
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      color = "primary"
                      variant='outlined'
                      onClick={updatePasswordInfo}
                    >Confirmar</Button>
                  </Grid>
                </Grid>
              </div>

            }
          </Box>

        </div>
      </div>
    </Layout>
  )

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(Profile);