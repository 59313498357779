import instanceAxios from "../config/axios";

export const url = '/api/suggestionpage';

export const addSuggestionPage = ({token, description, name}) => new Promise((resolve, reject)=>{
  if(token && name && description){
    instanceAxios.post(`${url}/create`,{
      name, description,
    }, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else if(!token) {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		});
	} else if(!name) {
    reject({
			status: 'error',
			info: 'Nombre no encontrado',
		});
  } else {
    reject({
			status: 'error',
			info: 'Descripcion no se encuentra',
		});
  }
});

export const updateSuggestionPage = ({token, description, name, _id, status}) => new Promise((resolve, reject)=>{
  if(token && name && description && _id && status){
    instanceAxios.put(`${url}/update/${_id}`,{
      name, description, status
    }, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else if(!token) {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		});
	} else if(!name) {
    reject({
			status: 'error',
			info: 'Nombre no encontrado',
		});
  } else if(!_id) {
    reject({
			status: 'error',
			info: 'ID no encontrado',
		});
  } else if(!status) {
    reject({
			status: 'error',
			info: 'Estado no encontrado',
		});
  } else {
    reject({
			status: 'error',
			info: 'Descripcion no se encuentra',
		});
  }
});

export const getAllSuggestionPage = (token) => new Promise((resolve, reject)=>{
  if(token){
    instanceAxios.get(`${url}/list`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else if(!token) {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		});
	} 
});

export const deleteSuggestionPage = ({token, _id}) => new Promise((resolve, reject)=>{
  if(token && _id){
    instanceAxios.delete(`${url}/delete/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else if(!token) {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		});
	} else {
		reject({
			status: 'error',
			info: 'ID no encontrado',
		});
	}
});

export const accessAdminEditorPage = ({token}) => new Promise((resolve, reject) => {
	if (token) {
		instanceAxios.get(`${url}/admin/editor/generate/token`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error.response.data));
	} else {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		});
	}
})
