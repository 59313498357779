/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Layout from '../layouts/DashboardLayout'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Axios
import { getUserData } from "../api/user.api";
import { getProjectsByOwner } from "../api/project.api"
import { getProjectsTeamByUser } from "../api/teams.api";
import { getProjectsByID } from "../api/project.api";

/* import { useLocation } from "react-router-dom"; */
import DynamicTable from "./dynamicTable";

const theme = createTheme();

const UserProfile = (props) => {
	const token = props.token
	const [linkData, setLinkData] = useState(props.match.params.idcode);
	const [data, setData] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [filter, setFilter] = useState(linkData)
	const [projectOwnData, setProjectOwnData] = useState()
	const [loadingProjects, setLoadingProjects] = useState(true);
	const [loadingCollabs, setLoadingCollabs] = useState(true);
	const [collaborationProjects, setCollaborationProjects] = useState()

	const [wasFound, setWasFound] = useState(false);

	const getData = async (filter) => {
		
		const res = await getUserData(filter, token );
		setData(res.data)
		
		if(res.data){
			setWasFound(true);
		}
		setIsLoading(false);
	}

	const getOwnProjectData = async (filter) => {
		const res = await getProjectsByOwner(filter, token);
		if(res.data.length>0){
			setProjectOwnData(res.data)
		}else{
			let obj={data:"No Data was found"}
			setProjectOwnData([obj])
		}
		setLoadingProjects(false)
	}

	const getCollabs = async (filter) =>{
		const res = await getProjectsTeamByUser(filter, token);
		
		if(res.data.length > 0 ){
			
			let objectData = []
			for(let i=0; i<res.data.length; i++){
				objectData.push(res.data[i].idProject)
			}
			const filteredData = await getProjectsByID(objectData, token);
			setCollaborationProjects(filteredData.data)
		}else{
			let obj={data:"No Data was found"}
			setCollaborationProjects([obj])
		}
		setLoadingCollabs(false)
	}

	useEffect(() => {
		if(props.token){
		getData(filter)
		getOwnProjectData(filter)
		getCollabs(filter)
		}
	}, [])

	if(!props.token){
		return(
			<Redirect to="/"/>
		)
	}

	return (
		data && !isLoading && !loadingProjects && !loadingCollabs && wasFound ?(
			<Layout>
				<div>
					<div>
						<header>
							<div className='user-table-bar'>
								<div className='user-table-header'>
									<h1>{data.firstName}</h1>
								</div>
							</div>
						</header>
					</div>
					<div style={{ alignItems: 'center', alignContent: 'center' }}>
						<ThemeProvider theme={theme}>
						<Box
							sx={{
								width: 1250,
								height: 'auto',
								marginLeft: 5,
								marginTop: 5


							}}
						>
							<Grid container spacing={2} style={{ padding: 40 }}>
								<Grid item xs={3}>
									<Box
										sx={{
											width: 200,
											height: 'auto',
											backgroundColor: 'gray',
											'&:hover': {
												backgroundColor: 'primary.main',
												opacity: [0.9, 0.8, 0.7],
											},
										}}
									>
										<img src={data.urlImage || "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"} 
										onError={(e)=>{e.target.onerror = null; e.target.src="https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"}}
										 width={"100%"} />
									</Box>
								</Grid>
								<Grid item xs={5}>
									{/*
									<SoftInput
										id="user-id-name"
										label={"Name"}
										variant="outlined"
										value={`${data.firstName} ${data.lastName}`}
										disabled
										fullWidth
									/>
									*/}
									<TextField
										id="user-id-name"
										label={"Name"}
										variant="outlined"
										value={`${data.firstName} ${data.lastName}`}
										disabled
										fullWidth
										margin = "dense"
									/>
									
								</Grid>
								<Grid item xs={3}>
									<Box

										sx={{
											width: 'auto',
											height: 'auto',
											backgroundColor: 'white',
											'&:hover': {
												backgroundColor: 'primary.main',
												opacity: [0.9, 0.8, 0.7],
											},
										}}
									>

									</Box>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={4}>
								<TextField
									id="user-email"
									label={"E-mail"}
									variant="outlined"
									value={`${data.email} `}
									disabled
									fullWidth
									margin = "dense"

								/>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs ={4}>
								<TextField
									id="user-company"
									label={"Company"}
									variant="outlined"
									value={`${data.company} `}
									disabled
									margin = "dense"
								/>
								</Grid>
							</Grid>
						</Box>
						</ThemeProvider>
					</div>
					<div>
						<h1>
							My Projects
						</h1>
						{projectOwnData.length !== 0 &&
							<DynamicTable data={projectOwnData} />
						}


					</div>
					<div>
						<h1>
							Collaborations on
						</h1>
						{collaborationProjects.length !== 0 &&
							<DynamicTable data={collaborationProjects} />
						}
					</div>
				</div>
			</Layout>
		) : (
			<Layout>
				 
				{wasFound && !isLoading  ?
					<h1>Loading</h1>:
					<h1>User Not Found</h1> 
				}
				
				
			</Layout>
		)

	)
}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(UserProfile);
