/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DynamicTable from '../components/dynamicTable';
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

//AXIOS API
import { listAllPartnerAdmin } from 'api/partner.api';

function Affiliates({ history, token }) {
	
	const [datos, setDatos] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const getData = async () => {
		
		const res = await listAllPartnerAdmin({token});
		setDatos(res.data);
    console.log(res.data);
		setIsLoading(false);
	}


	useEffect(() => {
		if(token){
			getData();
		}
	}, [])

	if(!token){
		return (
			<Redirect to="/"/>
		)
	}
	return (datos && !isLoading ? (
		<Layout>
			<div>
				<header>
					<div className='user-table-bar'>
						<div className='user-table-header'>
							<h1>Afiliados</h1>
						</div>
					</div>
				</header>
			</div>

			<div>
				{datos.length !== 0 ?
					<DynamicTable
						title="Afiliados"
						data={datos.map((item) => ({
							userid: item.userid,
							name: item.name,
							dni: item.dni,
							active: item.active ? 'Si' : 'No',
							userId: item.userId,
						}))}
						token={token}
					/>
          : (
            <h2 style={{textAlign: 'center', marginTop: 50}}>
              No hay Datos
            </h2>
          )
				}
			</div>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	)

}


const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(Affiliates);