import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { createPaymentByAdminConsultant } from '../api/logpayment.api';

export default function AddServerProject({token, handleData}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' onClick={handleClickOpen}>
        Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Server Project</DialogTitle>
        <DialogContent>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await createPaymentByAdminConsultant({
                  token,
                  email: e.target.email.value,
                  currency: e.target.currency.value,
                  description: e.target.description.value,
                  total: parseFloat(e.target.total.value),
                });
                if (handleData) {
                  handleData();
                }
                alert("Se agrego exitosamente");
              } catch (error) {
                console.log(error);
                if (error.info) {
                  alert(error.info);
                } else {
                  alert("Error al agregar el servidor");
                }
              }
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              name="email"
              label="Email"
              placeholder="Email"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="description"
              label="Description"
              placeholder="Description"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="currency"
              label="Currency"
              fullWidth
              variant="standard"
              select
            >
              <MenuItem value="HNL">
                Lempira
              </MenuItem>
              <MenuItem value="USD">
                Dolar
              </MenuItem>
            </TextField>
            <TextField
              margin="dense"
              name="total"
              label="Amount"
              type="text"
              fullWidth
              variant="standard"
            />
            <div style={{textAlign: 'center'}}>
              <Button type='submit'>
                Add
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}