import * as React from 'react';
/* import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader'; */
/* import DashboardIcon from '@mui/icons-material/Dashboard'; */
//import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
/* import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers'; */
/* import AssignmentIcon from '@mui/icons-material/Assignment'; */
/* import { Link } from "react-router-dom"; */

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
/* import Office from "examples/Icons/Office";
import Settings from "examples/Icons/Settings"; */
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
/* import CreditCard from "examples/Icons/CreditCard"; */
import Cube from "examples/Icons/Cube";

export const mainListItems = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    route: "/dashboard",
    icon: <Cube size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    route: "/users",
    icon: <CustomerSupport size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Projects",
    key: "projects",
    route: "/projects",
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Promotional Code",
    key: "promotional code",
    route: "/Promotionalcodes",
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Server Project",
    key: "serverproject",
    route: "/serverproject",
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Server proxy",
    key: "serverproxy",
    route: "/serverproxy",
    icon: <SpaceShip size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Suggestions",
    key: "suggestions",
    route: "/suggestions",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Request Project Payment",
    key: "requestrojectpayment",
    route: "/requestprojectpayment",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payment History",
    key: "logpayment",
    route: "/logpayment",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    route: "/support",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Suggestion Page",
    key: "suggestionpage",
    icon: <SpaceShip size="12px" />,
    route: "/suggestionpages",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Partners Page",
    key: "partnerspage",
    icon: <CustomerSupport size="12px" />,
    route: "/partners",
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Payment Methods",
    key: "methodpayment",
    route: "/methodpayment",
    icon: <Document size="12px" />,
    noCollapse: true,
  },
];

export const secondaryListItems = (
  <React.Fragment>
    {/* Sección de "Saved Reports" eliminada */}
  </React.Fragment>
);
