import axios from '../config/axios';

export const url = '/api/templateproject';

export const createTemplateProject = (
  token, idProject, price, currency
) => new Promise((resolve, reject) => {
  if (token && idProject && price && currency) {
    axios.post(`${url}/createtemplate/${idProject}`, {
      currency, price,
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else if (!currency) {
    reject({
      status: 'error',
      info: 'Moneda no definido'
    });
  } else if (!price) {
    reject({
      status: 'error',
      info: 'Precio no definido'
    });
  } else if (!idProject) {
    reject({
      status: 'error',
      info: 'Descripción no definida'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});