/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useState, useEffect } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Layout from '../layouts/DashboardLayout'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import JsZip from 'jszip';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


//AXIOS
import { getProjectDataByID } from "../api/project.api";
import { getProjectDatabases } from "../api/database.api";
import { getProjectPlugins } from "../api/pluginsproject.api";
import { getUserCalledByProject } from '../api/user.api';
import { getUsersTeamByProject } from "../api/teams.api";
import { getUserCollabsInProject } from "../api/user.api";
import { getDeploymentRequestByProject, zipDeploymentRequest } from '../api/deploymentRequest.api';
import { updateZipFiles } from "../api/deploymentRequest.api";
import { updateStatusDeploy } from "../api/deploymentRequest.api";

import DynamicTable from "./dynamicTable";

const theme = createTheme();

const handleZip = async (res, name) => {
  const zip = new JsZip();
  await zip.loadAsync(res, { base64: true });
  const blob = await zip.generateAsync({ type: 'blob' });
  const element = document.createElement('a');
  element.setAttribute('href', window.URL.createObjectURL(blob));
  element.setAttribute('download', `${name}.zip`);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
}

const getBase64 = file => {
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      const returnData = baseURL.split("base64,")
      resolve(returnData[1]);
    };
  })
}

const ProjectProfile = (props) => {

  const token = props.token

  const [linkData, setLinkData] = useState(props.match.params.idcode);
  const [filter, setFilter] = useState(linkData)

  const [data, setData] = useState();//Project Data
  const [isLoading, setIsLoading] = useState(true);//Loading project Data

  const [projectOwner, setProjectOwner] = useState("");

  const [dbData, setDbData] = useState();
  const [loadingDb, setLoadingDb] = useState(true);

  const [pluginData, setPluginData] = useState()
  const [loadingPlugins, setLoadingPlugins] = useState(true);

  const [userCollab, setUserCollab] = useState()
  const [loadingUsers, setLoadingUsers] = useState(true);

  const [drData, setDrdata] = useState();
  const [loadingDr, setLoadingDr] = useState(true);

  const [wasFound, setWasFound] = useState(false);

  const [seekerID, setSeekerID] = useState("");

  const [selectedType, setSelectedType] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const [selectedFile, setSelectedFile] = useState();

  const [isDeployed, setIsDeployed] = useState("");

  const [dialogStatusUpdate, setDialogStatusUpdate] = useState(false);

  const [statusSelection, setStatusSelection] = useState("");

  const getProjectData = async (filter) => {

    //const res = await getProjectDatabases(filter);
    const res = await getProjectDataByID(filter, token);
    setData(res.data)
    if (res.data) {
      setWasFound(true)
    }
    const user = await getUserCalledByProject(res.data.userId, token)
    setProjectOwner(user.data)
    setIsLoading(false);
  }

  const getDatabaseData = async (filter) => {
    const res = await getProjectDatabases(filter, token);
    if (res.data.length > 0) {
      setDbData(res.data);
    } else {
      let obj = { data: "No Data was found" }
      setDbData([obj])
    }
    setLoadingDb(false)
  }

  const getDeploymentRequest = async (filter) => {
    const res = await getDeploymentRequestByProject({ idProject: filter, token });
    if (res.data.length > 0) {
      setSeekerID(res.data[0]._id)
      setDrdata(res.data);
      setIsDeployed(res.data[0].type);
    } else {
      let obj = { data: "No Data was found" }
      setDrdata([obj])
    }
    setLoadingDb(false)
  }

  const getPluginsProjectData = async (filter) => {
    const res = await getProjectPlugins(filter, token)
    if (res.data.length > 0) {
      setPluginData(res.data);
    } else {
      let obj = { data: "No Data was found" }
      setPluginData([obj])
    }
    setLoadingPlugins(false)
  }

  const getUsersCollabingData = async (filter) => {
    const res = await getUsersTeamByProject(filter, token);

    if (res.data.length > 0) {

      let objectData = []
      for (let i = 0; i < res.data.length; i++) {
        objectData.push(res.data[i].userId)
      }
      const filteredData = await getUserCollabsInProject(objectData, token);
      setUserCollab(filteredData.data)
    } else {
      let obj = { data: "No Data was found" }
      setUserCollab([obj])
    }
    setLoadingUsers(false)
  }

  const handleComponent = (item) => {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={4}>
        <Button
          variant='outlined'
          onClick={async () => {
            try {
              const res = await zipDeploymentRequest({ _id: item._id, token })
              handleZip(res.dataBackend, 'backend')
              handleZip(res.dataBackendOffice, 'backendoffice')
              handleZip(res.dataFrontend, 'frontend')
              handleZip(res.dataFrontendOffice, 'frontendoffice')
            } catch (error) {
              alert('Error')
            }
          }}
        >
          Download
        </Button>
        </Grid>
        <Grid item xs={4}>
        <input
          type="file"
          accept=".zip"
          style={{ display: 'none' }}
          id="contained-button-file"
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            onClick={uploadFile}
            variant='outlined'
          >
            Upload Zips
          </Button>
        </label>
        </Grid>

        {isDeployed === "waiting" &&
          <Grid item xs={4}> 
          <Button
            onClick={openStatus}
            variant='outlined'
          >
            Update Status
          </Button>
          </Grid>
        }
        </Grid>
      </div>
    )
  }

  const handleFileUpload = (event) => {
    const temp = event.target.files
    if (temp[0]) {
      setSelectedFile(temp);
      setOpenDialog(true);
    }
  };

  const submitFiles = async (files) => {
    const _id = seekerID
    try {
      const res = await updateZipFiles(_id, token, files)
      if (res.status === 'success') {
        alert('Zips updated!')
      }
    } catch (error) {
      alert(error.info);
    }
    handleClose();
  }

  function uploadFile() {
    document.getElementById('contained-button-file').click()
  }

  function getDataInfo() {

    let res = ""
    if (typeof selectedFile === "undefined") {
      res = "No data"
      return res;
    } else {
      res = selectedFile[0].name;
      return res;
    }

  }

  const handleClose = () => {
    setSelectedFile();
    setSelectedType("");
    setOpenDialog(false);
  }

  const handleCloseSave = async () => {
    let obj = {};
    const data = await getBase64(selectedFile[0]);
    obj[selectedType] = data;
    submitFiles(obj);
  }

  //STATUS UPDATE
  const handleCloseStatusUpdate = () => {
    setStatusSelection("");
    setDialogStatusUpdate(false);
    window.location.reload(true);
  }

  const handleSaveStatus = async () => {
    if (statusSelection === "deployed") {
      const _id = seekerID
      const res = await updateStatusDeploy(_id, token);
    }
    handleCloseStatusUpdate();
  }

  function openStatus() {
    setDialogStatusUpdate(true);
  }

  //UPLOAD ZIPS & UPDATE STATUS

  const handleFileZip = () => {
    return (
      <div>
        <input
          type="file"
          accept=".zip"
          style={{ display: 'none' }}
          id="contained-button-file"
          onChange={handleFileUpload}
        />
        <label htmlFor="contained-button-file">
          <Button
            variant='contained'
            onClick={uploadFile}
          >
            Upload Zips
          </Button>
        </label>
      </div>
    )
  }


  useEffect(() => {
    if (props.token) {
      getProjectData(filter);
      getDatabaseData(filter);
      getPluginsProjectData(filter);
      getUsersCollabingData(filter);
      getDeploymentRequest(filter);
    }
  }, [])

  if (!props.token) {
    return (
      <Redirect to="/" />
    )
  }

  return (
    data && !isLoading && !loadingDb && !loadingPlugins && !loadingUsers ? (
      <Layout>
        <div>
          <div>
            <header>
              <div className='user-table-bar'>
                <div className='user-table-header'>
                  <h1>{data.name}</h1>
                </div>
              </div>
            </header>
          </div>

          <div>
            <ThemeProvider theme={theme}>
            <Box
              sx={{
                width: 1250,
                height: 'auto',
                marginLeft: 5,
                marginTop: 5


              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <TextField
                    id="user-id-name"
                    label={"owner"}
                    variant="outlined"
                    value={`${projectOwner.firstName} ${projectOwner.lastName}`}
                    disabled
                    fullWidth
                    margin="dense"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <TextField
                    id="user-id-name"
                    label={"Name"}
                    variant="outlined"
                    value={`${data.name}`}
                    disabled
                    fullWidth
                    margin="dense"
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="user-id-name"
                    label={"Slug"}
                    variant="outlined"
                    value={`${data.slug}`}
                    disabled
                    fullWidth
                    margin="dense"
                  />
                </Grid>

              </Grid>

            </Box>
            </ThemeProvider>
          </div>

          <div>
            <h1>
              Deployment Request
            </h1>

            {drData.length !== 0 &&
              <DynamicTable data={drData} haveComponent component={(handleComponent)} />
            }
          </div>

          <div>
            <h1>
              Databases
            </h1>
            {dbData.length !== 0 &&
              <DynamicTable data={dbData} />
            }
          </div>

          <div>
            <h1>
              Project Plugins
            </h1>
            {pluginData.length !== 0 &&
              <DynamicTable data={pluginData} />
            }
          </div>

          <div>
            <h1>
              Collaborators
            </h1>
            {userCollab.length !== 0 &&
              <DynamicTable data={userCollab} />
            }
          </div>

          {/*DIALOG ZIPS*/}
          <div>
            <Dialog
              open={openDialog}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Select Type of file to replace"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Zip File: {getDataInfo()}
                </DialogContentText>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Zip Type</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setSelectedType(e.target.value)}
                  >
                    <FormControlLabel value="backend.zip" control={<Radio />} label="Backend" />
                    <FormControlLabel value="frontend.zip" control={<Radio />} label="Frontend " />
                    <FormControlLabel value="backendoffice.zip" control={<Radio />} label="Backend Office" />
                    <FormControlLabel value="frontendoffice.zip" control={<Radio />} label="Frontend Office" />
                  </RadioGroup>
                </FormControl>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleCloseSave} autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          {/* DIALOG UPDATE STATUS */}
          <div>
            <Dialog
              open={dialogStatusUpdate}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Update Status"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Current Status: {isDeployed}
                </DialogContentText>
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">Set Status</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setStatusSelection(e.target.value)}
                    defaultValue={isDeployed}
                  >
                    <FormControlLabel value="deployed" control={<Radio />} label="Deployed" />
                    <FormControlLabel value="waiting" control={<Radio />} label="Waiting" />
                    <FormControlLabel disabled value="canceled" control={<Radio />} label="Cancel" />
                  </RadioGroup>
                </FormControl>

              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseStatusUpdate}>Cancel</Button>
                <Button onClick={handleSaveStatus} autoFocus>
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </div>

        </div>
      </Layout>
    ) : (
      <Layout>
        {wasFound ?
          <h1>Loading..</h1> :
          <h1>Project does not exist</h1>
        }
      </Layout>
    )
  )

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(ProjectProfile);