/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DynamicTable from '../components/dynamicTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
/* import TextField from '@mui/material/TextField'; */
import Layout from '../layouts/DashboardLayout'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SoftInput from "components/SoftInput";
import AddServerProxy from 'components/AddServerProxy';

//AXIOS API
import { listServerProxy } from 'api/serverproxy.api';

function ServerProxyData({ history, token }) {
	
	const [datos, setDatos] = useState();
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);


	const [filter, setFilter] = useState("");



	const getData = async (page, filter) => {
		
		try {
      const res = await listServerProxy({
        token
      });
      setDatos(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
	}


	useEffect(() => {
		if(token){
			getData(page, filter)
		}
	}, [])

	function setNextPage() {
		setIsLoading(true)
		getData(page + 1, filter)
		setPage(page + 1)
	}

	function setPreviousPage() {
		setIsLoading(true)
		getData(page - 1, filter)
		setPage(page - 1)
	}



	function requestSearch() {
		setIsLoading(true)
		getData(1, filter);
		setPage(1);
	}

	function cancelSearch() {
		setIsLoading(true)
		getData(1, "");
		setPage(1)
		setFilter("")
	}
	if(!token){
		return (
			<Redirect to="/"/>
		)
	}
	return (datos && !isLoading ? (
		<Layout>
			<div>
				<header>
					<div className='user-table-bar'>
						<div className='user-table-header'>
							<h1>Server Proxy</h1>
						</div>
					</div>
				</header>
			</div>
			<div className="grid-container">
				<Grid container spacing={3}>
					<Grid item xs={5}>
						<SoftInput
							placeholder = {`search by`} 
							value={filter}
							onChange={(e) => setFilter(e.target.value)}
						/>
					</Grid>
					<Grid item xs={3}>
						<Button variant="contained" onClick={() => requestSearch(filter)}>Search</Button>
					</Grid>
					<Grid item xs={2}>
						<Button variant="contained" onClick={() => cancelSearch()}>Reset</Button>
					</Grid>
          <Grid item xs={2}>
            <AddServerProxy handleData={getData} token={token} />
          </Grid>
				</Grid>
			</div>

			<div>
				{datos.length !== 0 &&
					<DynamicTable title="Projects" data={datos} />
				}
			</div>
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<p>page: {page}</p>
				</Grid>
			</Grid>
			<Grid container spacing={2}>

				<Grid item xs={2}>
					{page > 1 && page < totalPages &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
				<Grid item xs={2}>
					{page < totalPages &&
						<Button variant="contained" onClick={setNextPage}>
							Next
						</Button>
					}
				</Grid>

			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					{page >= totalPages && totalPages > 1 &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
			</Grid>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	)

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(ServerProxyData);
