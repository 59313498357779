/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import DynamicTable from '../components/dynamicTable';
/* import TextField from '@mui/material/TextField'; */
import Layout from '../layouts/DashboardLayout'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

//AXIOS API
import { listRequestProjectPaymentAdmin } from '../api/requestprojectpayment.api';

function RequestProjectPaymentData({ history, token }) {
	
	const [datos, setDatos] = useState();
	const [isLoading, setIsLoading] = useState(true);

	const getData = async () => {
		
		const res = await listRequestProjectPaymentAdmin({token});
		setDatos(res.data);
		setIsLoading(false);
	}


	useEffect(() => {
		if(token){
			getData();
		}
	}, [])

	if(!token){
		return (
			<Redirect to="/"/>
		)
	}
	return (datos && !isLoading ? (
		<Layout>
			<div>
				<header>
					<div className='user-table-bar'>
						<div className='user-table-header'>
							<h1>Solicitudes de Pago</h1>
						</div>
					</div>
				</header>
			</div>

			<div>
				{datos.length !== 0 ?
					<DynamicTable title="Solicitudes de Pago" data={datos} token={token} />
          : (
            <h2 style={{textAlign: 'center', marginTop: 50}}>
              No hay Datos
            </h2>
          )
				}
			</div>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	)

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(RequestProjectPaymentData);
