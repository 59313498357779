import instanceAxios from '../config/axios';

export const url = '/api/deploymentrequest';

export const getDeploymentRequestByProject = ({
  token, idProject
}) => new Promise((resolve, reject) => {
  if (token && idProject) {
    instanceAxios.get(`${url}/list/${idProject}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!idProject) {
    reject({
      status: 'error',
      info: 'Proyecto no definido'
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    })
  }
})

export const zipDeploymentRequest = ({
  _id, token
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    instanceAxios.get(`${url}/zip/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido',
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    })
  }
})

export const updateZipFiles = (_id, token, files) => new Promise((resolve, reject) => {
  if (token && _id && files) {
    instanceAxios.put(`${url}/updatezip/${_id}`, {
        files 
    }, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const { data } = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error))
  } else {
    reject({
      status: 'error',
      info: 'Datos invalidos'
    })
  }
})

export const updateStatusDeploy = (_id, token) => new Promise((resolve, reject) => {
  if(token && _id){
    instanceAxios.put(`${url}/updatestatus/${_id}`,{},
    {
      headers: {
        Authorization: token,
      },
    }).then((res)=>{
      const { data } = res;
      if(data.status === 'success'){
        resolve(data);
      }else{
        reject(data);
      }
    }).catch((error) => reject(error));
  }else if(!_id){
    reject({
      status: 'error',
      info: 'Identificador no definido',
    })
  }else{
    reject({
      status: 'error',
      info: 'Token no definido'
    })
  }
})