import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
/* import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import Chart from '../components/Charts'; */
import Deposits from '../components/Deposits';
/* import Orders from '../components/Orders'; */
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ProjectChart from '../components/projectCharts';
import RecentProjects from '../components/recentProjects';

function Dashboard({history,token}) {
  if(!token){
    return(
      <Redirect to ="/"/>
    )
  }
  return (
    <Layout>
      <Grid container spacing={3}>
        {/* Project Chart */}
        <Grid item xs={12} md={8} lg={9}>
          <ProjectChart />
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 120,
            }}
          >
            <Deposits />
          </Paper>
        </Grid>
        {/* Recent Projects */}
        <Grid item xs={12}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 'auto' }}>
          <RecentProjects />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}
const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(Dashboard);
