/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

//AXIOS API
import { getSuggestions } from '../api/suggestion.api';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SuggestionCard from '../components/suggestionCard';

const Suggestion = ({ history, token }) => {
	const [datos, setDatos] = useState();
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
  // const sampleData = [
  //   {
  //     title: 'UI Interface',
  //     description: 'Improve Color and Icons for settings in Editor',
  //     sector: 'editor',
  //     date: '2022-November-30',
  //   },
  //   {
  //     title: 'UI Interface',
  //     description: 'Improve Color and Icons for settings in Editor',
  //     sector: 'editor',
  //     date: '2022-November-30',
  //   },
  // ];

	const getData = async (page) => {
		try	{
			const res = await getSuggestions(token, page);
			// const { totalPage, filterData } = res.paginateInfo
			console.log(res);
			const { totalPage } = res.paginateInfo
			setTotalPages(totalPage)
			setDatos(res.data)
			setIsLoading(false);
		} catch (error) {
			console.log(error)
		}
	}


	useEffect(() => {
		if (token) {
			getData(page)
		}
	}, [])

	function setNextPage() {
		setIsLoading(true)
		getData(page + 1)
		setPage(page + 1)
	}

	function setPreviousPage() {
		setIsLoading(true)
		getData(page - 1)
		setPage(page - 1)
	}

	if (!token) {
		return (
			<Redirect to="/" />
		)
	}

	return (datos && !isLoading ? (
		<Layout>

			<div>
				<header>
					<div className='suggestion-table-bar'>
						<div className='suggestion-table-header'>
							<h1>Suggestion</h1>
						</div>
					</div>
				</header>
			</div>
			{datos && datos.length > 0 &&
			<div>
        {datos.map((index) => 
          <SuggestionCard
            title={index.title}
            description={index.description}
            sector={index.sector}
            date={index.createdAt.split('T')[0]}
          />
        )}
			</div>
			}
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<p>page: {page}</p>
				</Grid>
			</Grid>
			<Grid container spacing={2}>

				<Grid item xs={2}>
					{page > 1 && page < totalPages &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
				<Grid item xs={2}>
					{page < totalPages &&
						<Button variant="contained" onClick={setNextPage}>
							Next
						</Button>
					}
				</Grid>

			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					{page >= totalPages && totalPages > 1 &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
			</Grid>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	);

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(Suggestion);