import instanceAxios from "../config/axios";

export const url = '/api/teams';

export const getProjectsTeamByUser = (filter, token) => new Promise((resolve, reject) =>{
  if(filter){
    let _id = filter;
		instanceAxios.get(`${url}/getprojectsbycollab/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else{
    reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
  }
})

export const getUsersTeamByProject = (filter, token) => new Promise((resolve, reject) =>{
	if(filter){
		let _id = filter;
		instanceAxios.get(`${url}/getusersbycollab/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	}else{
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})