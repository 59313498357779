import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { addCodePromotion } from '../api/promotionalCode.api';

export default function AddPromotionalCode({ token, handleAddCode }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' onClick={handleClickOpen}>
        Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Promotional Code</DialogTitle>
        <DialogContent>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await addCodePromotion({
                  token,
                  code: e.target.code.value,
                  amount: parseFloat(e.target.amount.value),
                  initialDate: e.target.initialDate.value,
                  endDate: e.target.endDate.value,
                });
                if (handleAddCode) {
                  handleAddCode();
                }
                alert("Promotional code added successfully");
              } catch (error) {
                console.error(error);
                if (error.info) {
                  alert(error.info);
                } else {
                  alert("Error adding promotional code");
                }
              }
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              name="code"
              label="Code"
              placeholder="Promotional Code"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="amount"
              label="Amount"
              placeholder="Amount"
              type="number"
              step="0.01"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="initialDate"
              label="Initial Date"
              type="date"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="endDate"
              label="End Date"
              type="date"
              fullWidth
              variant="standard"
            />
            <div style={{textAlign: 'center', marginTop: '20px'}}>
              <Button type='submit'>
                Save
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
