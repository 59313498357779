import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, Button,
  FormControlLabel, FormLabel,
  Checkbox,
} from '@material-ui/core';
import { getUserData } from "../api/user.api";
import { setProjectAsTemplate } from 'api/project.api';
import { createTemplateProject } from 'api/templateproject.api';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  projectTitle: {
    fontSize: '24px',
    fontWeight: 600,
  },
  projectSubtitle: {
    fontSize: '15px',
    fontWeight: 400,
  },
  buttonStyle: {
    width: '150px',
    padding: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  }
}));

export default function ModalProjectTemplateConfig({
  projectInfo, open, onClose, token,
}) {
  const classes = useStyle();
  const [isTemplate, setIsTemplate] = useState(false);
  const [priceTemplate, setPriceTemplate] = useState(0);
  const [userEmail, setUserEmail] = useState('');
  // FOR DEFAULT CURRENCY TYPE
  const currency = 'HNL';

  useEffect(() => {
    setIsTemplate(projectInfo?.isTemplate);
    setPriceTemplate(projectInfo?.price);
    async function fetchData(){
      if (projectInfo !== undefined) {
        const res = await getUserData(projectInfo?.userId, token );
        setUserEmail(res.data.email);
      }
    }
    fetchData();
  }, [projectInfo, token]);

  function handleChangeToTemplate(data) {
    try {
      if (!projectInfo?.isTemplate) {
        // await setProjectAsTemplate(token, data);
        setIsTemplate(true);
        alert('Se ha actualizado el proyecto como template');  
      } else {
        alert('El Proyecto ya es template');
      }
    } catch (error) {
      console.log(error);
      alert(error.info);
    }
  }
  async function handleChangePrice(){
    try {
      // await setTemplateProjectPrice(token, projectInfo._id, parseFloat(priceTemplate));
      await setProjectAsTemplate(token, projectInfo._id, parseFloat(priceTemplate));
      await createTemplateProject(token, projectInfo._id, parseFloat(priceTemplate), currency);
      alert('Se ha actualizado el proyecto como template'); 
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Proyecto como Plantilla
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px',
          }}
        >
          <Box component="div" className={classes.projectTitle}>
            Proyecto: {projectInfo?.name}
          </Box>
          <Box component="div" className={classes.projectSubtitle}>
            Usuario dueño: {userEmail}
          </Box>
          <Box style={{marginTop: '10px', marginBottom: '10px'}}>
              <FormControlLabel
                control={<Checkbox checked={isTemplate} onChange={() => handleChangeToTemplate(projectInfo._id)} name="template" />}
                label="Plantilla"
              />
            {isTemplate && (
              <Box>
                <FormLabel component="legend">Precio Lps:</FormLabel>
                <TextField
                  onChange={(e) => setPriceTemplate(e.target.value)}
                  value={priceTemplate}
                  fullWidth
                  name="price"
                  type="number"
                  label="Precio Plantilla Lps:"
                  variant="standard"
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleChangePrice()}
                  className={classes.buttonStyle}
                >
                  Guardar
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
