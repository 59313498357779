import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, Button, MenuItem,
} from '@material-ui/core';
import { updateSuggestionPage } from 'api/suggestionpage.api';
import { addSuggestionPage } from 'api/suggestionpage.api';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  projectTitle: {
    fontSize: '24px',
    fontWeight: 600,
  },
  projectSubtitle: {
    fontSize: '15px',
    fontWeight: 400,
  },
  buttonAreaContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  buttonStyle: {
    width: '150px',
    padding: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  }
}));

export default function ModalSuggestionPage({
  _id, name, description, status, open, onClose, token, isEdit, closeWithCall,
}) {
  const classes = useStyle();
  const [nameValue, setNameValue] = useState('');
  const [descValue, setDescValue] = useState('');
  const [stateValue, setStateValue] = useState('');

  useEffect(() => {
    setNameValue(name);
    setDescValue(description);
    setStateValue(status);
  }, [_id, token, description, name, status]);

  async function handleInfoForm(){
    try {
      if (isEdit) {
        // console.log(nameValue, descValue, stateValue);
        await updateSuggestionPage({token, _id, name: nameValue, description: descValue, status: stateValue});
        alert('Actualizado Suggestion Page'); 
      } else {
        await addSuggestionPage({token, name: nameValue, description: descValue});
        alert('Agregado Suggestion Page'); 
      }
      closeWithCall();
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            {isEdit ? 'Editar ' : 'Agregar '}Suggestion Page
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px',
          }}
        >
          <Box style={{marginTop: '10px', marginBottom: '10px'}}>
              <Box>
                {isEdit && 
                  <Box component="div" className={classes.projectTitle}>
                    ID Suggestion: {_id}
                  </Box>
                }
                {/* <FormLabel component="legend">Name:</FormLabel> */}
                <TextField
                  onChange={(e) => setNameValue(e.target.value)}
                  defaultValue={name}
                  fullWidth
                  name="name"
                  type="string"
                  label="Nombre"
                  variant="standard"
                  style={{marginTop: '15px', marginBottom: '15px'}}
                />
                {/* <FormLabel component="legend">Descripcion:</FormLabel> */}
                <TextField
                  onChange={(e) => setDescValue(e.target.value)}
                  defaultValue={description}
                  fullWidth
                  name="description"
                  type="string"
                  label="Descripcion"
                  variant="standard"
                  multiline
                  rows={4}
                  maxRows={8}
                  style={{marginTop: '15px', marginBottom: '15px'}}
                />
                {
                  isEdit && (
                    <>
                    {/* <FormLabel component="legend">Status:</FormLabel> */}
                  <TextField
                    onChange={(e) => setStateValue(e.target.value)}
                    defaultValue={status}
                    fullWidth
                    name="status"
                    type="string"
                    label="Estado"
                    variant="standard"
                    select
                    style={{marginTop: '15px', marginBottom: '15px'}}
                  >
                    <MenuItem value="public">
                      Publico 
                    </MenuItem>
                    <MenuItem value="private">
                      Privado
                    </MenuItem>
                  </TextField>  
                  </>
                  )
                }
              </Box>
          </Box>
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleInfoForm()}
            className={classes.buttonStyle}
          >
            Guardar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
