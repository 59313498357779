import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: '15px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '95%',
    boxShadow: '1px 4px #DCDCDC',
    border: '1px solid',
    borderColor: '#DCDCDC',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
  },
  content: {
    flex: 1,
  },
  dateText: {
    color: 'rgba(20, 73, 153, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  titleText: {
    color: 'rgba(255, 119, 35, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  textTimeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectorText: {
    color: 'rgba(204, 204, 204, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  subtitleText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
    [theme.breakpoints.down('xs')]: {fontSize: '16px'},
  },
  areaSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  areaTitlePriorityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  priorityText: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  }, 
  priorityOutput: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
}));

export default function SuggestionCard({
  date, title, description, sector, userInfo, project, section, priority,
}) {
  const classes = useStyles();

  function handleStyleText(condition){
    if(condition === 'critical'){
      return {
        color: 'red',
      };
    } else if (condition === 'high'){
      return {
        color: 'orange',
      };
    } else if (condition === 'medium'){
      return {
        color: 'yellow',
      }
    }
    return {
      color: 'gray',
    }
  }

  return (
    <Box component="div" className={classes.mainBox}>
      <Typography component="h5" variant="h5" className={classes.dateText}>
        {'Sent by: '}
        {userInfo}
        {' on: '}
        {date}
      </Typography>
      <Card className={classes.root}>
        <Box component="div" className={classes.details}>
          <CardContent className={classes.content}>
            <Box component="div" className={classes.textTimeContainer}>
              <Box component="div" className={classes.areaTitlePriorityContainer}>
                <Typography component="h5" variant="h5" className={classes.titleText}>
                  {'Issue: '}
                  {title}
                </Typography>
                <Typography component="h5" variant="h5" className={classes.titleText}>
                  {'Project: '}
                  {project}
                </Typography>
                <Box component="div" style={{display: 'flex', flexDirection: 'row'}}>
                  <Typography component="h5" variant="h5" className={classes.priorityText}>
                    {'Priority: '}
                  </Typography>
                  <Typography component="h5" variant="h5" className={classes.priorityOutput} style={handleStyleText(priority)}>
                    {priority}
                  </Typography>
                </Box>
              </Box>
              <Box component="div" className={classes.areaSectionContainer}>
                <Box>
                  <Typography component="h5" variant="h5" className={classes.sectorText}>
                    {'In the sector: '}
                  </Typography>
                  <Typography component="h5" variant="h5" className={classes.sectorText} style={{fontWeight: 'bold', color: '#000', fontStyle: 'italic'}}>
                    {sector}
                  </Typography>
                </Box>
                <Box>
                  <Typography component="h5" variant="h5" className={classes.sectorText}>
                    {'Area: '}
                  </Typography>
                  <Typography component="h5" variant="h5" className={classes.sectorText} style={{fontWeight: 'bold', color: '#000', fontStyle: 'italic'}}>
                    {section}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="textSecondary" className={classes.subtitleText} style={{fontWeight: 'bold', color: '#000', fontStyle: 'italic'}}>
                {'Info: \n'}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.subtitleText}>
                {description}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </Card>
    </Box>
  );
}
