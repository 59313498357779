import * as React from 'react';
/* import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography'; */
import { ThemeProvider } from '@emotion/react';
import theme from "assets/theme";
/* import Title from './Title'; */
import MiniStatisticsCard from 'examples/Cards/StatisticsCards/MiniStatisticsCard';

/* function preventDefault(event) {
  event.preventDefault();
} */

export default function Deposits() {
  return (
    <ThemeProvider theme={theme}>
      <MiniStatisticsCard
        title={{ text: "Recent Deposits" }}
        count="$3,024.00"
        percentage={{ color: "success", text: "On March 15 2019"}}
        icon={{ color: "info", component: "paid" }}
      />
    </ThemeProvider>
    /*
    <React.Fragment>
      <Title>Recent Deposits</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2019
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
    </React.Fragment>
    */
  );
}