import axios from 'axios';

// const url = 'http://localhost:4002/';

const url = 'https://admin-backoffice.loftyapps.com';

const instanceAxios = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
  },
})

export default instanceAxios;