import axios from '../config/axios';

const url = '/api/codepromotion';

export const addCodePromotion = ({
  token, code, amount, initialDate, endDate
}) => new Promise((resolve, reject) => {
  if (code && amount && initialDate && endDate && token) {
    axios.post(`${url}/create`, {
      code, amount, initialDate, endDate
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else if (!code) {
    reject({
      status: 'error',
      info: 'Código no definido'
    });
  } else if (!amount) {
    reject({
      status: 'error',
      info: 'Monto no definido'
    });
  } else if (!initialDate) {
    reject({
      status: 'error',
      info: 'Fecha de inicio no definida'
    });
  } else if (!endDate) {
    reject({
      status: 'error',
      info: 'Fecha de fin no definida'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const deleteCodePromotion = ({
  token, _id
}) => new Promise((resolve, reject) => {
  if (_id && token) {
    axios.delete(`${url}/delete/${_id}`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else if (!token) {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  }
});

export const listCodePromotionForPartner = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
