/**
=========================================================
* Soft UI Dashboard React - v4.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState, useEffect } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.

import PropTypes from "prop-types";

// @material-ui core components

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";

// Soft UI Dashboard React components

import SoftBox from "components/SoftBox";

// Soft UI Dashboard React examples

import Breadcrumbs from "examples/Breadcrumbs";
import Swal from "sweetalert2";


// Custom styles for DashboardNavbar

import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";
// Soft UI Dashboard React context

import {
  useSoftUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";
import { logUserOut } from 'api/useradmin.api';

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar } = controller;
  const route = useLocation().pathname.split("/").slice(1);
  useEffect(() => {
        // Setting the navbar type

    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    // A function that sets the transparent state of the navbar.

    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }
 /**
     The event listener that's calling the handleTransparentNavbar function when
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);
    // Call the handleTransparentNavbar function to set the state with the initial value.

    handleTransparentNavbar();

        // Remove event listener on cleanup

    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  const handleLogout = async () => {
    const token = localStorage.getItem("token");

    try {
      await logUserOut({ token });
      localStorage.removeItem("token");
      window.location.reload();
    } catch (error) {
      console.error("Error en el logout:", error);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Lo sentimos no se pudo cerrar sesión',
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="primary"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <SoftBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })} display="flex" alignItems="center">
            <IconButton
              size="small"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={light ? "text-white" : "text-dark"}>
                {miniSidenav ? "menu_open" : "menu"}
              </Icon>
            </IconButton>
            <Button color="inherit" onClick={handleLogout} startIcon={<img src="https://cdn-icons-png.flaticon.com/128/992/992680.png" alt="Cerrar Sesion"style={{ width: 20, height: 20 }} />}>
            Cerrar Sesión
            </Button>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;


