import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { MenuItem } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { addServerProxy } from '../api/serverproxy.api';

export default function AddServerProject({token, handleData}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant='contained' onClick={handleClickOpen}>
        Add
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Server Project</DialogTitle>
        <DialogContent>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              try {
                await addServerProxy({
                  ip: e.target.ip.value,
                  diskGB: e.target.diskGB.value,
                  limitDomain: e.target.limitDomain.value,
                  numberDomain: 0,
                  passwordKey: e.target.passwordKey.value,
                  privateSshkey: e.target.privateSshkey.value,
                  provider: e.target.provider.value,
                  ramMB: e.target.ramMB.value,
                  token: token,
                  typeSsh: e.target.typeSsh.value,
                  username: e.target.username.value,
                });
                if (handleData) {
                  handleData();
                }
                alert("Se agrego exitosamente");
              } catch (error) {
                console.log(error);
                if (error.info) {
                  alert(error.info);
                } else {
                  alert("Error al agregar el servidor");
                }
              }
            }}
          >
            <TextField
              autoFocus
              margin="dense"
              name="ip"
              label="IP Address"
              placeholder="IP Address"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="username"
              label="Username"
              placeholder="Username"
              type="text"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="ramMB"
              label="Ram Megabytes"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="diskGB"
              label="Disk Gigabytes"
              placeholder="Disk Gigabytes"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="provider"
              label="Provider"
              fullWidth
              variant="standard"
              select
            >
              <MenuItem value="aws">
                AWS
              </MenuItem>
              <MenuItem value="digitalocean">
                Digital Ocean
              </MenuItem>
              <MenuItem value="contabo">
                Contabo
              </MenuItem>
            </TextField>
            <TextField
              margin="dense"
              name="limitDomain"
              label="Limit domains"
              type="number"
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="typeSsh"
              label="Type SSH"
              fullWidth
              select
              variant="standard"
            >
              <MenuItem value="password">
                Password
              </MenuItem>
              <MenuItem value="sshkey">
                SSH Key
              </MenuItem>
            </TextField>
            <TextField
              margin="dense"
              name="privateSshkey"
              label="Private SSH"
              type="text"
              multiline
              rows={5}
              fullWidth
              variant="standard"
            />
            <TextField
              margin="dense"
              name="passwordKey"
              label="Password"
              type="password"
              fullWidth
              variant="standard"
            />
            <div style={{textAlign: 'center'}}>
              <Button type='submit'>
                Add
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}