import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  Button,
} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(47, 115, 234, 1)',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  partnerTitle: {
    fontSize: '18px',
    fontWeight: 600,
  },
  partnerSubtitle: {
    fontSize: '15px',
    fontWeight: 400,
  },
  buttonStyle: {
    width: '150px',
    padding: '5px',
    marginTop: '10px',
    marginBottom: '10px',
  }
}));

export default function ModalInfoPartner({
  partnerInfo, open, onClose, token,
}) {
  const classes = useStyle();

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Informacion de Afiliado
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px',
          }}
        >
          <Box component="div" className={classes.partnerTitle}>
            Afiliado: {partnerInfo?.name}
          </Box>
          <Box component="div" className={classes.partnerTitle}>
            DNI Usuario: {partnerInfo?.dni}
          </Box>
          <Box component="div" className={classes.partnerSubtitle}>
            ID: {partnerInfo?.userid}
          </Box>
          <Box component="div" className={classes.partnerSubtitle}>
            Contrato: {partnerInfo?.contrato}
          </Box>
          <Box component="div" className={classes.partnerSubtitle}>
            Fecha Inicio: {partnerInfo?.initialDate}
          </Box>
          <Box component="div" className={classes.partnerSubtitle}>
            Fecha Terminacion: {partnerInfo?.endDate}
          </Box>
          <Box component="div" className={classes.partnerSubtitle}>
            Activo: {partnerInfo?.active ? 'Si' : 'No'}
          </Box>
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
