/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SuggestionPageCard from 'components/SuggestionPageCard';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getAllSuggestionPage } from 'api/suggestionpage.api';
import ModalSuggestionPage from 'components/ModalSuggestionPage';
import ModalDeleteSelectedItem from 'components/ModalDeleteSelectedItem';
import { deleteSuggestionPage } from 'api/suggestionpage.api';
import { accessAdminEditorPage } from 'api/suggestionpage.api';


function SuggestionPage({ history, token }) {
	
	const [datos, setDatos] = useState();
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
  const [originData, setOriginData] = useState([]);
  const itemsPerPage = 10;
	const [isLoading, setIsLoading] = useState(true);
  const [onEdit, setOnEdit] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editElement, setEditElement] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState('');

	const getData = async () => {
		
		const res = await getAllSuggestionPage(token);
    const totalNumPage = Math.ceil(res.data.length / itemsPerPage);
    setTotalPages(totalNumPage);
    setOriginData(res.data);
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedData = res.data.slice(startIndex, endIndex);
		setDatos(slicedData);
		setIsLoading(false);
	}

  function searchData(currentPage) {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const slicedData = originData.slice(startIndex, endIndex);
		setDatos(slicedData);
    setIsLoading(false);
  }

	useEffect(() => {
		if(token){
			getData()
		}
	}, []);

	function setNextPage() {
		setIsLoading(true)
		searchData(page + 1)
		setPage(page + 1)
    
	}

	function setPreviousPage() {
		setIsLoading(true)
		searchData(page - 1)
		setPage(page - 1)
	}

  function openModal(){
    setOpenDialog(true);
  }

  function openDeleteItem(index) {
    setDeleteItem(index);
    setOpenDelete(true);
  }

  function closeDeleteItem() {
    setDeleteItem('');
    setOpenDelete(false);
  }

  function editSuggestionPage (index) {
    setEditElement(index);
    setOnEdit(true);
    setOpenDialog(true);
  }

  function closeDialog() {
    setEditElement('');
    setOnEdit(false);
    setOpenDialog(false);
  }

  async function handleCloseWithCall() {
    setEditElement('');
    setOnEdit(false);
    setOpenDialog(false);
    getData();
  }

  async function handleSuggestionPageDeletion(){
    try {
      await deleteSuggestionPage({token, _id: deleteItem._id});
      alert('Se ha eliminado el dato'); 
      setDeleteItem('');
      setOpenDelete(false);
      getData();
    } catch (error) {
      console.log(error);
    }
  }

  async function handleOpenEditor(index) {
    try {
      const resTokenEditor = await accessAdminEditorPage({token});
      const url = `${resTokenEditor.url}/admin/${index._id}?accesstoken=${resTokenEditor.token}`;
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
    } catch (error) {
      console.log(error);
    }
  }

	if(!token){
		return (
			<Redirect to="/"/>
		)
	}
	return (datos && !isLoading ? (
		<Layout>
			<div>
				<header>
					<div className='user-table-bar'>
						<div className='user-table-header'>
							<h1>Suggestions Page</h1>
						</div>
					</div>
				</header>
			</div>
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end', alignItems: 'center', marginTop: '20px', marginBottom: '20px'}}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => openModal()}
        >
          Agregar
        </Button>
      </div>
      <div>
        <ModalSuggestionPage
          open={openDialog}
          onClose={() => closeDialog()}
          _id={editElement?._id || ''}
          name={editElement?.name || ''}
          description={editElement?.description || ''}
          status={editElement?.status || ''}
          token={token}
          isEdit={onEdit}
          closeWithCall={handleCloseWithCall}
        />
      </div>
      <div>
        <ModalDeleteSelectedItem
          _id={deleteItem?._id}
          open={openDelete}
          onClose={() => closeDeleteItem()}
          handleDelete={() => handleSuggestionPageDeletion()}
          title={'Eliminar Sugerencia de Pagina'}
          infodata={`La sugerencia: ${deleteItem?.name}`}
        />
      </div>

			{datos?.length > 0 && (<div>
        {datos?.map((index) => 
          <SuggestionPageCard
            title={index.name}
            description={index.description}
            status={index.status}
            _id={index._id}
            setEditSuggestion={() => editSuggestionPage(index)}
            deleteItem={() => openDeleteItem(index)}
            openEditor={() => handleOpenEditor(index)}
          />
        )}
			</div>)}
			<Grid container spacing={2}>
				<Grid item xs={4}>
					<p>page: {page}</p>
				</Grid>
			</Grid>
			<Grid container spacing={2}>

				<Grid item xs={2}>
					{page > 1 && page < totalPages &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
				<Grid item xs={2}>
					{page < totalPages &&
						<Button variant="contained" onClick={setNextPage}>
							Next
						</Button>
					}
				</Grid>

			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					{page >= totalPages && totalPages > 1 &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
			</Grid>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	)

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(SuggestionPage);