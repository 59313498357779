/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import DynamicTable from "./dynamicTable";
import { getRecentOnes } from "../api/project.api";
import Title from './Title';

function RecentProjects({ history, token }) {

  const [resultData, setResultData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const getData = async () => {
    const res = await getRecentOnes(token);
    // console.log(res)
    setResultData(res.data)
    setIsLoading(false)
  }

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [])

  return (
    <React.Fragment>
      {!isLoading && 
        <div>
          <Title>Recent Projects</Title>
          <DynamicTable title="Projects" data={resultData} />
        </div>
      }
    </React.Fragment>
  )
}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(RecentProjects);