import axios from '../config/axios';

const url = '/api/serverproject';

export const addServerProject = ({
  ip, username,
  ramMB, diskGB, provider,
  limitProject, numberProjects,
  sshDigital, typeSsh,
  privateSshkey,
  passwordKey, token,
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.post(`${url}/create`, {
      ip, username,
      ramMB, diskGB, provider,
      limitProject, numberProjects,
      sshDigital, typeSsh,
      privateSshkey,
      passwordKey,
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const listServerProject = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error.response?.data);
    });
  } else {
    reject({ 
      status: 'error',
      info: 'Token no definido'
    });
  }
});
