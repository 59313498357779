/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
/* import Error404 from './pages/Error404'; */
import UserData from './pages/Users';
import ProjectData from './pages/Projects';
import UserProfile from './components/userProfile';
import ProjectProfile from './components/projectProfile';
import Profile from './pages/profile';
//<Route exact path="*" component={Error404} />
import {addUser, deletetoken} from './actions/auth.actions';
import { getLoggedUser } from './api/useradmin.api';
import {connect} from 'react-redux';
/* import LogHeader from './components/logHeader'; */
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Suggestion from './pages/Suggestion';
import Support from './pages/Support';
import ServerProject from './pages/ServerProject';
import ServerProxy from 'pages/ServerProxy';
import LogPayment from './pages/LogPayment';
import RequestProjectPaymentData from 'pages/RequestProjectPaymentData';
import SuggestionPage from 'pages/SuggestionPage';
import Affiliates from 'pages/Affiliates';
import methodPayments from 'pages/methodPayments';
import PromotionalCodeData from './pages/Promotionalcodes';

function App({token, addUserApp, deletetokenApp}) {
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    getLoggedUser({token}).then((res)=>{

      if(res.status==="success"){
      addUserApp(res.user);
      setLoading(false);
      }else{
        deletetokenApp();
      setLoading(false)
      }
    }).catch(()=>
    {
      deletetokenApp();
      setLoading(false)
    }
    )
  },[])
  if(loading){
    return (<h1>Loading</h1>)
  }
  return (
    <Router>
      {/* {<LogHeader/>} */}
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/forgotpassword" component={ForgotPassword}/>
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <Route exact path="/my_profile" component={Profile} />
        <Route exact path="/users" component={UserData} />
        <Route exact path="/projects" component={ProjectData} />
        <Route exact path="/promotional-codes" component={PromotionalCodeData} />
        <Route exact path="/requestprojectpayment" component={RequestProjectPaymentData} />
        <Route exact path="/profile/:idcode" component={UserProfile}/>
        <Route exact path="/project/:idcode" component={ProjectProfile} />
        <Route exact path="/suggestions" component={Suggestion} />
        <Route exact path="/serverproject" component={ServerProject} />
        <Route exact path="/serverproxy" component={ServerProxy} />
        <Route exact path="/logpayment" component={LogPayment} />
        <Route exact path="/support" component={Support} />
        <Route exact path="/suggestionpages" component={SuggestionPage} />
        <Route exact path="/partners" component={Affiliates} />
        <Route exact path="/methodpayment" component={methodPayments} />
        <Route exact path="/Promotionalcodes" component={PromotionalCodeData} />
      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addUserApp: (user) => dispatch(addUser(user)),
  deletetokenApp: () => dispatch(deletetoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
