import axios from "../config/axios";

const url = `/api/requestprojectpayment`;

export const listRequestProjectPaymentAdmin = ({
  token
}) => new Promise((resolve, reject) => {
  if (token) {
    axios.get(`${url}/list`, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    })
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});

export const updateStatusProjectAdmin = ({
  token, _id, status
}) => new Promise((resolve, reject) => {
  if (token && _id && status) {
    axios.put(`${url}/update/status/${_id}`, {
      status
    }, {
      headers: {
        Authorization: token
      }
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data);
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else if (!status) {
    reject({
      status: 'error',
      info: 'Estatus no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
})

