import instanceAxios from '../config/axios';

export const url = '/api/useradmin';

export const loginUser = ({email, password}) => new Promise((resolve, reject)=>{
  if(email && password){
    instanceAxios.post(`${url}/login`, {
      email, password,
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  }else if(!email){
    reject({
      status: 'error',
      info: 'Correo no definido',
    });
  }else{
    reject({
      status: 'error',
      info: 'Password no definida',
    });
  }
})

export const getLoggedUser = ({token}) => new Promise((resolve, reject) => {
  if (token) {
    
    instanceAxios.get(`${url}/logged`, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});

export const logUserOut = ({token}) => new Promise((resolve, reject) => {
  
  if (token) {
    
    instanceAxios.post(`${url}/logout`,{}, {
      headers: {
        Authorization: token,
      },
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else {
    reject({
      status: 'error',
      info: 'Token no definido en la data',
    });
  }
});

export const updateInfo = ({fullName, token}) => new Promise((resolve, reject)=>{
  if(token && fullName){
    instanceAxios.put(`${url}/update`,{fullName,},{
      headers:{
        Authorization:token,
      }, 
    }).then((res) => {
      const {data} = res;
      if(data.status === 'success'){
        resolve(data)
      }else{
        reject(data)
      }
    }).catch((error)=>reject(error))
  }else if(!token){
    reject({
      status: 'error',
      info: 'Token no definido en la data',
    });
  }else if(!fullName){
    reject({
      status: 'error',
      info: 'Nombre no definido',
    });
  }
});

export const changePassword = ({newPassword, confirmPassword, token}) => new Promise((resolve, reject)=>{
  if(token && newPassword && confirmPassword && newPassword === confirmPassword){
    instanceAxios.post(`${url}/change/password`,{newPassword, confirmPassword,},{
      headers:{
        Authorization:token,
      }, 
    }).then((res) => {
      const {data} = res;
      if(data.status === 'success'){
        resolve(data)
      }else{
        reject(data)
      }
    }).catch((error)=>reject(error))
  }else if(!newPassword){
    reject({
      status: 'error',
      info: 'Contraseña no definida',
    });
  }else if(!confirmPassword){
    reject({
      status: 'error',
      info: 'Confirmación de contraseña no definida',
    });
  }else if(newPassword !== confirmPassword){
    reject({
      status: 'error',
      info: 'Confirmación de contraseña no concuerda con la contraseña dada',
    });
  }else{
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
})
