import instanceAxios from "../config/axios";

export const url = '/api/pluginsprojects';

export const getProjectPlugins = (filter, token) => new Promise((resolve, reject)=>{
  if(filter){
    let idProject = filter;
    instanceAxios.get(`${url}/getprojectplugins/${idProject}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else {
		reject({
			status: 'error',
			info: 'Pagina no encontrada, verifique el codigo del proyecto',
		})
	}
})