import React from 'react';
import TextField from '@material-ui/core/TextField';
import {resetPassword} from '../api/reset.api';

const ResetPassword = ({match, history}) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100%',
    }}
  >
    <div className="container-centered">
      <h2 style={{fontSize: '2rem'}} className="text-center mb4">
        Reseteando Contraseña
      </h2>
      <form
        onSubmit={async (e) => {
          const {params} = match;
          const {token} = params;
          e.preventDefault();
          try {
            await resetPassword({
              password: e.target.password.value,
              confirmPassword: e.target.confirmPassword.value,
              token,
            });
            
            history.push('/');
          } catch (error) {
            console.log(error)
          }
        }}
      >
        <div>
          <TextField fullWidth name="password" type="password" label="Contraseña" variant="outlined" />
        </div>
        <br/>
        <div className="mt-4">
          <TextField fullWidth name="confirmPassword" type="password" label="Confirmar Contraseña" variant="outlined" />
        </div>
        <div className="text-center mt-4">
          <button
            style={{
              background: '#201549',
            }}
            type="submit"
          >
            Resetear
          </button>
        </div>
      </form>
    </div>
  </div>
);

export default ResetPassword;