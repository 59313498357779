import instanceAxios from "../config/axios";

export const url = '/api/projects';

export const getProjects = (page, filter,token) => new Promise((resolve, reject) => {
	if (page) {
		instanceAxios.get(`${url}/getprojects`, {
			headers:{
				Authorization: token
			},
			params: {
				page,
				filter
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	} else {
		reject({
			status: 'error',
			info: 'Pagina no encontrada, verifique el numero',
		})
	}
})

export const getProjectsByOwner = (filter, token) => new Promise((resolve, reject) =>{
	if (filter) {
		let _id = filter;
		instanceAxios.get(`${url}/projectown/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	} else {
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getProjectsByID = (ids, token) => new Promise((resolve, reject) =>{
	if(ids){
		instanceAxios.get(`${url}/projectcollab`, {
			headers:{
				Authorization: token
			},
			params: {
				ids
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	}else{
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getProjectDataByID = (filter, token) => new Promise((resolve, reject)=>{
	if(filter){
		let _id = filter;

		instanceAxios.get(`${url}/projectdataid/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			
			const { data } = res;
			if (data.status === 'success') {
				
				resolve(data)
			} else {
				
				reject(data)
			}
		}).catch((error) => reject(error))
	}else{
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getAllData = (token) => new Promise((resolve, reject)=>{
	if(token){
		instanceAxios.get(`${url}/searchall`,{
			headers:{
				Authorization: token
			},
		}).then((res)=>{
			const { data } = res;
			if (data.status === 'success') {
				console.log("ok")
				resolve(data)
			} else {
				console.log("Not ok")
				reject(data)
			}
		}).catch((error)=>reject(error))
	}else{
		reject({
			status: 'error',
			info: 'No existe el token',
		})
	}
})

export const getRecentOnes = (token) => new Promise((resolve, reject) =>{
	if(token){
		instanceAxios.get(`${url}/searchrecent`,{
			headers:{
				Authorization: token
			},
		}).then((res)=>{
			const { data } = res;
			if (data.status === 'success') {
				console.log("ok")
				resolve(data)
			} else {
				console.log("Not ok")
				reject(data)
			}
		}).catch((error)=>reject(error))

	}else{
		reject({
			status: 'error',
			info: 'No existe el token',
		})
	}
})

export const setProjectAsTemplate = (token, _id, price) => new Promise((resolve, reject) => {
	if (token && _id && price) {
		instanceAxios.put(`${url}/setastemplate/${_id}`, {
			price,
		}, {
			headers: {
				Authorization: token
			},
		}).then((res) => {
			const {data} = res;
			if (data.status === 'success') {
				resolve(data);
			} else {
				reject(data);
			}
		}).catch((error) => reject(error));
	} else if (!price) {
		reject({
			status: 'error',
			info: 'No existe el Precio del proyecto',
		})
	} else if (!_id) {
		reject({
			status: 'error',
			info: 'No existe el ID del proyecto en la info',
		})
	} else {
		reject({
			status: 'error',
			info: 'No existe el token',
		})
	}
});

export const setTemplateProjectPrice = (token, _id, price) => new Promise((resolve, reject) => {
	if (token && _id && price) {
		instanceAxios.put(`${url}/setpricetemplate/${_id}`, {
			price,
		},{
			headers: {
				Authorization: token
			}
		}).then((res) => {
			const {data} = res;
			if (data.status === 'success') {
				resolve(data);
			} else {
				reject(data);
			}
		}).catch((error) => reject(error));
	} else if (!_id) {
		reject({
			status: 'error',
			info: 'No se encontro el ID del proyecto',
		})
	} else if (!price) {
		reject({
			status: 'error',
			info: 'No se encontro el precio a designar',
		})
	} else {
		reject({
			status: 'Error',
			info: 'No se encontro el token',
		})
	}
});

export const updateProjectDeployedCode = ({
	token, idProject
}) => new Promise((resolve, reject) => {
	if (token && idProject) {
		instanceAxios.post(`${url}/update/project/code`, {
			idProject,
		}, {
			headers: {
				Authorization: token,
			}
		}).then((res) => {
			if (res.data.status === 'success') {
				resolve(res.data);
			} else {
				reject(res.data);
			}
		}).catch((error) => {
			reject(error?.response?.data);
		});
	} else if (!idProject) {
		reject({
			status: 'error',
			info: 'Proyecto no definido'
		});
	} else {
		reject({
			status: 'error',
			info: 'Token no definido'
		});
	}
})
