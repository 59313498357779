import instanceAxios from "../config/axios";

export const url = '/api/suggestion';

export const getSuggestions = (token, page) => new Promise((resolve, reject)=>{
  if(token && page){
    instanceAxios.get(`${url}/getinfo`, {
			headers:{
				Authorization: token
			},
      params: {
				page,
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
  }else if(!token) {
		reject({
			status: 'error',
			info: 'Token no encontrado',
		})
	} else {
    reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
  }
});
