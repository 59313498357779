/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Layout from '../layouts/DashboardLayout';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

//AXIOS API
import { getSupport } from '../api/support.api';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import SupportCard from '../components/supportCard';

const Support = ({ history, token }) => {
	const [datos, setDatos] = useState();
	const [page, setPage] = useState(1);
	const [priorityValue, setPriorityValue] = useState('');
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(true);
  // const today = new Date();
  // const sampleData = [
  //   {
  //     title: 'UI Interface',
  //     description: 'Improve Color and Icons for settings in Editor',
  //     sector: 'editor',
  //     date: '2022-November-30',
  //     section: 'Add Data',
  //     userInfo: 'Tester Alpha',
  //     priority: 'low',
  //     project: 'Alpha One'
  //   },
  //   {
  //     title: 'Cant See deployed Page',
  //     description: 'Cannot connect to my webpage',
  //     sector: 'deploy',
  //     date: '2022-November-30',
  //     section: 'connection',
  //     userInfo: 'Tester Bravo',
  //     priority: 'critical',
  //     project: 'Testers',
  //   },
  // ];

	const getData = async (page, filter) => {
		try {
			const res = await getSupport(token, page, filter);
			console.log(res);
			const { totalPage } = res.paginateInfo
			setTotalPages(totalPage)
			setDatos(res.data)
			setIsLoading(false);
		} catch (error) {
			console.log(error);
		}
	}


	useEffect(() => {
		if (token) {
			getData(page, priorityValue);
		}
	}, [])

	function setNextPage() {
		setIsLoading(true)
		getData(page + 1, priorityValue);
		setPage(page + 1)
	}

	function setPreviousPage() {
		setIsLoading(true)
		getData(page - 1, priorityValue);
		setPage(page - 1)
	}

	if (!token) {
		return (
			<Redirect to="/" />
		)
	}

	function handleChange(value){
		setPriorityValue(value);
		getData(1, value);
		setPage(1);
	}

	return (datos && !isLoading ? (
		<Layout>

			<div>
				<header>
					<div className='suggestion-table-bar'>
						<div className='suggestion-table-header'>
							<h1>Support</h1>
						</div>
					</div>
				</header>
			</div>
			<Box component="div" style={{marginTop: '25px', marginBottom: '10px', width: '200px', height: '50px'}}>
				<FormControl fullWidth>
        	<InputLabel id="priority-select-label-Input" style={{fontSize: '16px', top: '-5px'}}>Priority</InputLabel>
        	<Select
          	labelId="priority-select-label"
          	id="priority-select"
          	value={priorityValue}
          	label="Priority"
          	onChange={(e) => handleChange(e.target.value)}
						disabled={!datos}
        	>
          	<MenuItem value={'critical'}>Critical</MenuItem>
          	<MenuItem value={'high'}>High</MenuItem>
          	<MenuItem value={'medium'}>Medium</MenuItem>
						<MenuItem value={'low'}>Low</MenuItem>
        	</Select>
      	</FormControl>
			</Box>
			<div>
        {datos.map((index) => 
          <SupportCard
            title={index.title}
            description={index.description}
            sector={index.sector}
            date={index.createdAt.split('T')[0]}
            priority={index.priority}
            project={index.project}
            section={index.sectionPerSector}
            userInfo={index.userInfo}
          />
        )}
			</div>

			<Grid container spacing={2}>
				<Grid item xs={4}>
					<p>page: {page}</p>
				</Grid>
			</Grid>
			<Grid container spacing={2}>

				<Grid item xs={2}>
					{page > 1 && page < totalPages &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
				<Grid item xs={2}>
					{page < totalPages &&
						<Button variant="contained" onClick={setNextPage}>
							Next
						</Button>
					}
				</Grid>

			</Grid>
			<Grid container spacing={2}>
				<Grid item xs={2}>
					{page >= totalPages && totalPages > 1 &&
						<Button variant="contained" onClick={setPreviousPage}>
							Previous
						</Button>
					}
				</Grid>
			</Grid>
		</Layout>

	) : (
		<Layout>
			<h1>Loading..</h1>
		</Layout>
	)
	);

}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(Support);