/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
/* import { useTheme } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts'; */
import Title from './Title';
import { useState, useEffect } from 'react';
import { getAllData } from '../api/project.api';
import { connect } from 'react-redux';
import GradientLineChart from '../components/Charts/GradientLineChart';
import { ThemeProvider } from '@emotion/react';
import theme from "assets/theme";

function createData(time, amount) {
  return { time, amount };
}

function ProjectChart({ history, token }) {
  const [datos, setDatos] = useState();
  const [labelData, setLabelData] = useState();
  const [infoData, setInfoData] = useState();
  const getData = async () => {
    const res = await getAllData(token);
    var groupedData = []
    var counter = 0;
    res.data.forEach(element => {
      var dateData = element.createdAt.split('T')[0];
      var year = dateData.split('-')[0]
      var month = dateData.split('-')[1]
      var joint = year + '-'+month

      var temp = groupedData[counter]

      if(temp){
        if(temp.time === joint){
          groupedData[counter].amount++;
        }else{
          groupedData.push(createData(joint, 1))
          counter++;
        }
      }
      else{
        groupedData.push(createData(joint, 1))
      }
    });
    var labelTemp = [];
    var dataTemp = [];
    for (let index = 0; index < groupedData.length; index++) {
      labelTemp.push(groupedData[index].time);
      dataTemp.push(groupedData[index].amount);
      
    }
    setLabelData(labelTemp);
    setInfoData(dataTemp);
    setDatos(groupedData)
  }

  useEffect(() => {
    if (token) {
      getData()
    }
  }, [])

  return (
      <ThemeProvider theme={theme}>
        <Title>Project Info</Title>
        {
        <GradientLineChart
        title="Project Creation Info"
        chart={{
          labels: labelData,
          datasets: [
            {
              label: "Websites",
              color: "dark",
              data: infoData,
            },
          ],
        }}
        />
      }
      </ThemeProvider>
  );
}

const mapStateToProps = (state) => state.auth;
export default connect(mapStateToProps)(ProjectChart);