import instanceAxios from "../config/axios";

export const url = '/api/user';

export const getUsers = (page, filter, token) => new Promise((resolve, reject) => {

	if (page) {
		instanceAxios.get(`${url}/getusers`, {
			headers:{
				Authorization: token
			},
			params: {
				page,
				filter
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	} else {
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getUserData = (filter, token) => new Promise((resolve, reject) => {
	if (filter) {
		let _id = filter;
		instanceAxios.get(`${url}/profile/${_id}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	} else {
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getUserCalledByProject = (filter, token) => new Promise((resolve, reject) =>{
	if(filter){
		let userId = filter;
		instanceAxios.get(`${url}/projectowner/${userId}`, {
			headers:{
				Authorization: token
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	}else{
		reject({
			status: 'error',
			info: 'Pagina no encontrada',
		})
	}
})

export const getUserCollabsInProject = (ids, token) => new Promise((resolve, reject) =>{
	if(ids){
		instanceAxios.get(`${url}/collaborators`, {
			headers:{
				Authorization: token
			},
			params: {
				ids
			},
		}).then((res) => {
			const { data } = res;
			if (data.status === 'success') {
				resolve(data)
			} else {
				reject(data)
			}
		}).catch((error) => reject(error))
	}else{
		reject({
			status: 'error',
			info: 'Error en busqueda de colaboradores',
		})
	}
})