import React from 'react';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createResetToken} from '../api/reset.api';
import Button from '@material-ui/core/Button';
const ForgotPassword = ({token, history}) =>{
  if (token) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100vh',
        width: '100%',
      }}
    >
      <div className="container-centered">
        <h2 style={{fontSize: '2rem'}} className="text-center mb4">
          <b>
            Restablecer Contraseña
          </b>
        </h2>
        <form
          
          onSubmit={async (e) => {
            e.preventDefault();
            try {
              await createResetToken({
                email: e.target.email.value,
              });
              
              history.push('/');
            } catch (error) {
              console.log(error)
            }
          }}
        >
          <div>
            <TextField fullWidth name="email" type="email" label="Correo" variant="outlined" />
          </div>
          <div className="text-center mt-4">
            <br/>
            <Button
              variant='contained'
              color="primary"
              type="submit"
            >
              Enviar
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(ForgotPassword);